const textQuery = `
  markDefs[]{
    ...,
    _type == "internalButton" => {
      ...,
      reference->
    }
  }
`

module.exports.pageQuery = `
  ...,
  content {
    ...,
    main {
      ...,
      modules[] {
        ...,
        _type == 'cerealCta' => {
          ...,
          image {
            ...,
            asset-> {
              _id,
              'id': _id
            }
          }
        },
        _type == 'mainHero' => {
          ...,
          backgroundImage {
            ...,
            asset->
          },
          shopLinks[]->
        },
        _type == 'doubleCta' => {
          ...,
          products[] {
            ...,
            characterImage {
              asset-> {
                'id': _id
              }
            },
            productReference-> {
              ...,
              content {
                ...,
                main {
                  ...,
                  cerealImage {
                    ...,
                    asset-> {
                      _ref,
                      _id,
                      'id': _id
                    }
                  }
                }
              }
            }
          }
        },
        _type == 'galleryGrid' => {
          ...,
          products[]-> {
            ...,
            content {
              ...,
              main {
                ...,
                mainImage {
                  ...,
                  asset-> {
                    _id,
                    'id': _id
                  }
                }
              }
            }
          }
        },
        _type == 'pressGrid' => {
          ...,
          logos[]->
        },
        _type == 'imageSlideshow' => {
          ...,
          images[] {
            asset-> {
              _id
            }
          }
        },
        _type == 'shoppable' => {
          ...,
          characterImage {
            asset-> {
              'id': _id
            }
          },
          productReference-> {
            ...,
            content {
              ...,
              main {
                ...,
                cerealImage {
                  ...,
                  asset-> {
                    'id': _id
                  }
                }
              }
            }
          }
        },
        _type == 'pressModule' => {
          ...,
          press[]-> {
            ...,
            logo {
              asset-> 
            }
          }
        },
        _type == 'splitModule' => {
          ...,
          image {
            asset-> {
              'id': _id
            }
          },
          text[] {
            ...,
            ${textQuery}
          }
        },
        _type == 'instagram' => {
          ...,
          characterIcon {
            asset-> {
              'id': _id
            }
          },
          instagrams[] {
            ...,
            asset->
          }
        },
        _type == 'newFlavors' => {
          ...,
          flavorImages[] {
            asset-> {
              _id,
              'id': _id
            }
          }
        },
        _type == 'imageText' => {
          ...,
          image {
            asset-> {
              'id': _id
            }
          },
          text[] {
            ...,
            ${textQuery}
          }
        }
      }
    }
  }
`

module.exports.productQuery = `
  ...,
  content {
    ...,
    main {
      ...,
      cerealImage {
        asset-> {
          _id,
          'id': _id
        }
      },
      mainImage {
        asset-> {
          _id,
          'id': _id
        }
      },
      modules[] {
        ...,

        _type == 'varietyPack' => {
          ...,
          product-> {
            ...,
            'id': _id
          },
          products[]-> {
            ...,
            content {
              ...,
              main {
                ...,
                singleImage {
                  ...,
                  asset-> {
                    _id,
                    'id': _id
                  }
                }
              }
            }
          }
        },
        _type == 'howItWorks' => {
          ...,
          popupFacts[] {
            ...,
            _type == 'popupFact' => {
              ...,
              text[] {
                ...,
                _type == 'image' => {
                  _key,
                  asset->
                }
              }
            }
          }
        },
        _type == 'floatingHead' => {
          ...,
          headImage {
            asset-> {
              _id,
              'id': _id
            }
          }
        },
        _type == 'chatroulette' => {
          ...,
          headImage {
            asset-> {
              _id,
              'id': _id
            }
          }
        },
        _type == 'reviews' => {
          ...,
          reviews[] {
            ...,
            reviewHead {
              asset-> {
                _id,
                'id': _id
              }
            }
          }
        },
        _type == 'productCTA' => {
          ...,
          characterImage {
            asset-> {
              _id,
              'id': _id
            }
          },
          productReference-> {
            ...,
            content {
              ...,
              main {
                ...,
                cerealImage {
                  asset-> {
                    'id': _id,
                    _id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`