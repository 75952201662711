import React, { useEffect } from 'react'
import cx from 'classnames'

import { RenderModules } from 'src/utils/renderModules'

import { useSetPage } from 'src/context/siteContext'
import { SEO } from 'src/components/SEO'
import { trackPageView } from "src/components/analytics"

export interface PageProps {
  pageContext: {
    main: {
      modules: [],
      slug: {
        current: string
      },
      title?: string
    },
    meta: {}
  }
  transitionStatus: string
}

const Page = ({
  path,
  pageContext
}: PageProps) => {
  const setPage = useSetPage()
  const {
    main: {
      modules,
      title,
      slug
    },
    meta
  } = pageContext
  useEffect(() => {
    setPage(pageContext.main.slug.current)
    trackPageView(title)
  }, [0])
  const url = slug.current === 'home' ? '' : path
  return (
    <div className="ac">
      <SEO metaInfo={meta} pagePath={url} />
      <div className="mxa x al site__main">
        {RenderModules(modules)}
      </div>
    </div>
  )
}

export default Page